import React from 'react'
import ImageIcons from '../imageComponent/ImageIcons';
import LazyLoad from 'react-lazy-load';
const SwitchMyInternet = () => {
  return (
    <section>
      <div className='container mx-auto'>
        <div className='md:mb-[50px] max-md:mb-[40px] max-sm:mb-[30px]'>
          <LazyLoad className='xl:mb-[25px] sm:mb-[10px] mb-0' height={48}>
              <img className='xl:w-[420px] lg:w-[380px] sm:w-[300px] w-[270px] mx-auto' src={ImageIcons.intertainment} alt='Intertainment' height='' width=''
              />
          </LazyLoad>
          <h1 className='text-center font-normal xl:text-[32px] lg:text-[30px] md:text-[28px] sm:text-[26px] text-[22px]'>India's Trusted Choice for the Fastest Internet Provider</h1>
        </div>
        <div className='flex justify-center items-center'>
          <div className='relative z-10 xl:w-[calc(30%_+_100px)] lg:w-[calc(30%_+_90px)] sm:w-[calc(30%_+_70px)] w-[calc(30%_+_45px)]'>
            <div className='flex justify-end items-center lg:gap-[35px] max-lg:gap-[15px] max-sm:gap-[7px] xl:mb-[90px] lg:mb-[70px] sm:mb-[50px] mb-[25px]'>
              <h4 className='xl:text-[26px] lg:text-[22px] sm:text-[20px] text-[13px] font-medium mb-0 text-right'>High<br />Speed broadband</h4>
              <LazyLoad className='xl:w-[88px] lg:w-[80px] sm:w-[70px] w-[48px] min-w-[48px]'>
                  <img src={ImageIcons.highSpeedIcon} alt='High Speed broadband' height='' width='' />
              </LazyLoad>
            </div>
            <div className='flex justify-end items-center lg:gap-[35px] max-lg:gap-[15px] max-sm:gap-[7px] xl:mb-[90px] lg:mb-[70px] sm:mb-[50px] mb-[25px] xl:pr-[65px] sm:pr-[50px] pr-[30px]'>
              <h4 className='xl:text-[26px] lg:text-[22px] sm:text-[20px] text-[13px] font-medium mb-0 text-right'>Unlimited<br />Internet</h4>
              <LazyLoad className='xl:w-[88px] lg:w-[80px] sm:w-[70px] w-[48px] min-w-[48px]'>
                <img src={ImageIcons.unlimitedInternet} alt='Unlimited Internet'className="w-full" height='' width='' />
              </LazyLoad>
            </div>
            <div className='flex justify-end items-center lg:gap-[35px] max-lg:gap-[15px] max-sm:gap-[7px]'>
              <h4 className='xl:text-[26px] lg:text-[22px] sm:text-[20px] text-[13px] font-medium mb-0 text-right'>IPTV</h4>
              <LazyLoad className='xl:w-[88px] lg:w-[80px] sm:w-[70px] w-[48px] min-w-[48px]'>
                <img src={ImageIcons.iptvIcon} alt='IPTV' className="w-full" height='' width='' />
              </LazyLoad>
            </div>
          </div>
          <LazyLoad className='xl:-mx-[100px] lg:-mx-[90px] sm:-mx-[70px] -mx-[45px] w-[40%] 2xl:h-[560px] min-h-[120px]'>
              <img src={ImageIcons.switchMyinternetImg} alt="Switch Myinternet" className="w-full" height='' width='' />
          </LazyLoad>
          <div className='relative z-10 xl:w-[calc(30%_+_100px)] lg:w-[calc(30%_+_90px)] sm:w-[calc(30%_+_70px)] w-[calc(30%_+_45px)]'>
            <div className='flex items-center lg:gap-[35px] max-lg:gap-[15px] max-sm:gap-[7px] xl:mb-[90px] lg:mb-[70px] sm:mb-[50px] mb-[25px]'>
              <LazyLoad className='xl:w-[88px] lg:w-[80px] sm:w-[70px] w-[48px] min-w-[48px]'>
                <img src={ImageIcons.supportIcon} alt='24x7 Support'className="w-full" height='' width='' />
              </LazyLoad>
              <h4 className='xl:text-[26px] lg:text-[22px] sm:text-[20px] text-[13px] font-medium mb-0'>24x7<br />Support</h4>
            </div>
            <div className='flex items-center lg:gap-[35px] max-lg:gap-[15px] max-sm:gap-[7px] xl:mb-[90px] lg:mb-[70px] sm:mb-[50px] mb-[25px] xl:pl-[65px] sm:pl-[50px] pl-[30px]'>
              <LazyLoad className='xl:w-[88px] lg:w-[80px] sm:w-[70px] w-[48px] min-w-[48px]'>
                <img src={ImageIcons.uniformDataFlow} alt='Uniform Data Flow' className="w-full" height='' width='' />
              </LazyLoad>
              <h4 className='xl:text-[26px] lg:text-[22px] sm:text-[20px] text-[13px] font-medium mb-0'>Uniform<br />Data Flow</h4>
            </div>
            <div className='flex items-center lg:gap-[35px] max-lg:gap-[15px] max-sm:gap-[7px]'>
              <LazyLoad className='xl:w-[88px] lg:w-[80px] sm:w-[70px] w-[48px] min-w-[48px]'>
                <img src={ImageIcons.leasedLineIcon} alt='Leased Line'className="w-full" height='' width='' />
              </LazyLoad>
              <h4 className='xl:text-[26px] lg:text-[22px] sm:text-[20px] text-[13px] font-medium mb-0'>leased<br />Line</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default SwitchMyInternet
import React from 'react'
import Layout from '../components/shared/layout'
import InnerPageBanner from '../components/shared/innerPageBanner'
import ImageIcons from '../components/imageComponent/ImageIcons'
import { IoCheckmarkDoneSharp } from 'react-icons/io5'
const Disclaimer = () => {
  return (
    <Layout>
      <InnerPageBanner title='Disclaimer' innerPageBanner={ImageIcons.Disclaimer} />
      <section>
        <div className='container'>
          <p>We encourage you to read this disclaimer carefully  to understand the limitations and responsibilities which are associated with our services.</p>
          <h4>General Information</h4>
          <p>Although we are always committed to offer you with the best of internet services possible, it is crucial to understand that</p>
          <ul className='mb-[20px]'>
            <li className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />We offer our customers round the clock services, however sometimes there may be maintenance or any unforeseen issues which can lead to interruptions.</li>
            <li className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />The speed of the internet can vary according to the location, network traffic and device.</li>
          </ul>
          <h4>No Warranties</h4>
          <p>Services are provided on the basis of “as is” and “as available”. We do not provide our customers with any warranty regarding the reliability, availability and suitability of our services for any particular purposes.</p>
          <ul className='mb-[20px]'>
            <li className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />MyInternet service providers do not provide any guarantee of uninterrupted services.</li>
            <li className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />My Internet does not take the onus of any content which has been accessed via our internet services.</li>
          </ul>
          <h4>Limitation of LIability</h4>
          <p>My Internet is not liable to-</p>
          <ul className='mb-[20px]'>
            <li className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />Your loss of data, profits or any interruptions in the business.</li>
            <li className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />Damages resulting from usage are not our responsibility.</li>
          </ul>
          <h4>User Responsibilities</h4>
          <ul className='mb-[20px]'>
            <li className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />We ask you to use our services by complying to all the rules and laws.</li>
            <li className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />You need to take appropriate steps to secure your device.</li>
          </ul>
          <h4>Third-Party Services</h4>
          <p>We may have links to third-party websites or services but we can not be held responsible for the content or practices of their services.</p>
          <h4>Changes to the Disclaimer</h4>
          <p>The disclaimer can be updated any time. We ask you to review this page regularly, as your continued usage will signify your acceptance to the disclaimer.</p>
        </div>
      </section>
    </Layout>
  )
}
export default Disclaimer
import React from 'react';
import Layout from '../components/shared/layout';
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';
import Message from '../components/about/message';
import LeadershipTeam from '../components/about/leadershipTeam';
import Founded from '../components/about/founded';
import { MdLiveTv, MdOutlineSupportAgent } from 'react-icons/md';
import { SiFastapi } from 'react-icons/si';
import { TiArrowShuffle } from "react-icons/ti";
import SEO from '../components/shared/seo';
const About = () => {
  return (
    <>
    <SEO title='My Internet Broadband - Know About Us' description='Learn about My Internet mission to provide reliable internet solutions. Get our commitment to innovation and customer satisfaction. Read more.'/>
    <Layout>
      <InnerPageBanner title='About-Us' innerPageBanner={ImageIcons.Aboutus} />
      <Founded />
      <Message />
      <LeadershipTeam />
      <section>
        <div className="container">
          <div className='max-w-[800px] md:mb-[50px] mb-[40px] text-center mx-auto'>
            <h2>Our<span className='text-[#f48533]'> Services</span> Include</h2>
          </div>
          <div className='grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 gap-[25px]'>
            {servicesData.map((item, i) => (
              <div key={i} className='relative rounded-[10px] shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] bg-[#fff] py-[35px] lg:px-[35px] px-[30px]  group after:absolute after:content-[""] after:w-full after:h-full after:hover:bg-[linear-gradient(157deg,#f48533_0%,#3308d3_100%)] after:rounded-[10px] after:origin-left after:opacity-0 after:invisible after:scale-y-0 after:right-0 after:bottom-0 after:duration-700 after:hover:opacity-100 after:hover:visible after:hover:scale-y-100 hover:text-white'>
                <div className="relative z-10 ">
                  <div className='flex justify-center items-center group-hover:rotate-90 duration-1000 rotate-45 mb-[30px] mt-[10px] w-[70px] h-[70px] text-[30px] rounded-[10px] bg-[#fff] shadow-[5px_8.66px_30px_0px_rgba(0,0,0,0.15)] group-hover:!text-[#000]'>
                    <i className='-rotate-45 group-hover:-rotate-90 delay-700 duration-1000'>{item.servicesIcon}</i>
                  </div>
                  <h4>{item.title}</h4>
                  <p className='mb-0'>{item.text}</p>
                  {/* <Link className='text-[#f48533] hover:!text-[#fff] font-medium' to={`#${item.readMore}`} onClick={() => handleLinkClick(`${item.readMore}`)}>Read More <HiArrowRight className='inline-block ml-[5px]' /></Link> */}
                </div>
              </div>
            ))}
          </div>
          <div className='mt-[50px] text-center'>
            <p className='text-[19px] font-light'>Besides this, our team is constantly working for innovation and improving our services so that you are provided with the best and seamless internet using experience. Join our community of thousands of satisfied customers and get the best experience.</p>
          </div>
        </div>
      </section>
    </Layout>
    </>
  )
}
const servicesData = [
  {
    title: "Ultra-Fast Broadband",
    text: "We offer our customers impeccable speed, reliability, thereby allowing you to not to worry about buffering and slow internet speed. With our services, you can stream your favourite shows, enjoy gaming and can seamlessly work from home, as we provide you with the power you need.",
    servicesIcon: <SiFastapi />,
    readMore: 'Broadband',
  },
  {
    title: "Flexible Plans",
    text: "We provide our customers with affordable plans which do not prove heavy on their pockets. Users can also get the plans customised to their needs.",
    servicesIcon: <TiArrowShuffle />,
    readMore: 'IPTV',
  },
  {
    title: "IPTV Services",
    text: "One of the greatest services offered by us is free IPTV with our internet services. Our subscribers are provided with access to free IPTV, so with us, you can immerse yourself into a totally new world of entertainment.",
    servicesIcon: <MdLiveTv />,
    readMore: 'LeaseLine',
  },
  {
    title: "Customer Support",
    text: "Our customer support team is available round the clock in order to assist our customers with any of their queries or issues, so that they can enjoy seamless and hassle-free internet services.",
    servicesIcon: <MdOutlineSupportAgent />,
    readMore: 'WifiHotspots',
  }
]
export default About
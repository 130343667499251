import React, { useEffect, useState } from 'react';
import Layout from '../components/shared/layout';
// import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';
// import blogsData from "../components/utils/data/blogs.json";
import { useLocation, useNavigate } from "react-router-dom";
import { Link, useParams } from "react-router-dom"
import { FiUser } from 'react-icons/fi';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { FaMicrophone } from "react-icons/fa";
import axios from 'axios';
import SEO from '../components/shared/seo';

const BlogDetails = () => {

  const { pathname } = useLocation();
  const { handle } = useParams();

  // const [, setBlogDetails] = useState();

  const [, setActiveLink] = useState('');
  const navigate = useNavigate()
  const handleLinkClick = (blogId) => {
    setActiveLink(blogId);
  };

  // useEffect(() => {
  //   const selectedBlog = blogsData.find(blog => blog.handle === handle)
  //   setBlogDetails(selectedBlog)
  // }, [handle])

  const [allBlogs, setAllBlogs] = useState([])
  const [blog, setBlog] = useState("")
  const fetchBlogs = async () => {
    const response = await axios.get(`${process.env.REACT_APP_URL}user/getblog`);
    const filteredData = response.data.data.filter(item => item.isActive === true);
    const sortedBlogs = filteredData.sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt));
    const latest6Blogs = sortedBlogs.slice(0, 6);
    const foundBlog = filteredData.find(blog => pathname.toLowerCase() === `/blogs/${blog.handle}`);

    if (!foundBlog) {
      navigate('*');
    } else {
      setBlog(foundBlog)
    }

    setAllBlogs(latest6Blogs)
  }
  useEffect(() => {
    fetchBlogs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, handle])

  // const dateObject = new Date(blogDetails?.publishedAt);
  // const formattedDate = dateObject.toLocaleDateString("en-US", {
  //     year: "numeric",
  //     month: "short",
  //     day: "numeric",
  // });

  return (
    <>
      <SEO title={blog?.metaTitle} description={blog?.metaDescription} keywords={blog?.keyword} />
      <Layout>
        {/* <InnerPageBanner headingH2='headingH2' blogsLink="blogsLink" title={blog?.title} innerPageBanner={ImageIcons.contactBg} /> */}

        <section className='bg-[#fbfbfd] border border-[#dfdfdf]'>
          <div className='container'>
            <div className='flex max-lg:flex-wrap justify-between xl:gap-[40px] gap-[30px]'>

              <div className='lg:w-[70%] w-full' >
                <div className='mb-[30px] xl:h-[550px] lg:h-[500px] md:h-[400px] h-auto rounded-[10px] overflow-hidden'>
                  <img className='w-full h-full object-cover object-center' src={blog?.image} alt={blog?.alttag}
                  />
                </div>
                <div className='mb-[30px] flex items-center justify-between border-b pb-[30px] border-[#ddd]'>
                  <div className='flex items-center'><FiUser className='inline-block text-[#f48533] mr-[8px]' /> {blog?.author}</div>
                  <div className='flex items-center'><FaRegCalendarAlt className='inline-block text-[#f48533] mr-[8px]' /> {blog?.publishedAt}</div>
                </div>
                <h1 className='xxl:text-[44px] lg:text-[40px] md:text-[34px] text-[28px] '>{blog?.title}</h1>
                <p className='ql-editor' dangerouslySetInnerHTML={{ __html: blog?.description }} />
              </div>

              <div className='lg:w-[30%] w-full'>
                <div className='mb-[40px] xl:px-10 xl:py-10 max-xl:px-5 max-xl:py-5 rounded-[10px] shadow-[0_3px_10px_5px_rgba(0,0,0,0.08)] bg-[#fff]'>
                  <div className='title-sidebar mb-5'>
                    <h4>Recent Post</h4>
                    <hr className="green-hr-line" />
                  </div>

                  {allBlogs?.slice(0, 4).map((item) => {
                    return (
                      <Link
                        key={item?.blogId}
                        className={`mt-[30px] flex group`}
                        to={`/blogs/${item?.handle}`}
                        onClick={() => handleLinkClick(item?.blogId)}
                      >
                        <div className={`flex justify-center items-center h-[90px] w-[90px] duration-500 relative rounded-[10px] overflow-hidden group-hover:after:absolute after:content-[''] after:w-full after:h-full after:left-0 after:top-0 after:bg-[linear-gradient(157deg,#f48533_0%,#3308d3_100%)] after:duration-500 after:opacity-50 ${pathname === `/blogs/${item?.handle}` ? 'after:absolute' : ''} `}>
                          <img className={`h-full w-full object-cover object-center duration-500 group-hover:rotate-[5deg] group-hover:scale-150 ${pathname === `/blogs/${item?.handle}` ? 'rotate-[5deg] scale-150' : ''}  `} src={item.image} alt={item.altText} />
                        </div>
                        <div className={`md:pl-[10px] md:ml-[10px] pl-[7px] ml-[7px] text-left sm:w-[calc(100%_-_90px)] w-[calc(100%_-_90px)] ${pathname === `/blogs/${item?.handle}` ? 'border-[#f48533] border-l' : 'border-[#fff] border-l'}`}>
                          <div className='text-[#f48533] mb-[5px]'>{item?.publishedAt}</div>
                          <h5 className="mb-0 text-[18px] leading-[24px] line-clamp-2">{item?.title}</h5>
                        </div>
                      </Link>
                    );
                  })}
                </div>

                <div className='box-shodow-info rounded-[10px] overflow-hidden shadow-[0_3px_10px_5px_rgba(0,0,0,0.1)]'>
                  <div className='contact-sidebar-info'>
                    <div className='contact-img-gril'>
                      <img src={ImageIcons.help} alt='blog' className='w-full' />
                    </div>
                    <div className=' bg-[linear-gradient(-6deg,#f38432_74.9%,transparent_75.2%)] linear-gradient(-6deg, #0b7132 74.9%, #fff0 75.2%) relative text-center xl:px-10 xl:pb-10 max-xl:px-8 max-xl:pb-8 mt-[-80px]'>
                      <div className='relative z-20 w-[90px] h-[90px] mx-auto bg-[#fff] flex items-center justify-center rounded-full'>
                        <FaMicrophone className='text-[#f38432] text-[35px]' />
                      </div>
                      <h4 className='mb-3 mt-4 text-white font-medium'>Do you need any help?</h4>

                      <p className='font-medium text-white cursor-pointer mb-[5px]'> <Link to="tel:+91 9464001234" >+91 9464001234</Link></p>
                      <p className='font-medium text-white cursor-pointer mb-0'><Link to="mailto:info@myinternet.services">info@myinternet.services</Link></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      </Layout>
    </>
  )
}

export default BlogDetails
import React from 'react';
import { Link } from 'react-router-dom';
const InnerPageBanner = ({ title = "", headingH2 = "", innerPageBanner = "", innerPageBannerAtl = "", blogsLink = "" }) => {
    return (
        <section className='relative md:py-[150px] max-md:py-[60px] after:content-[""] after:absolute after:left-0 after:right-0 after:top-0 after:w-full after:h-full after:bg-[#f48533] after:opacity-50'>
            <img  className='absolute top-0 left-0 w-full h-full object-cover object-center' src={innerPageBanner} alt={innerPageBannerAtl} />
            <div className='heading_tittle container relative z-10'>
                {headingH2 === "headingH2" ? <h2 className="h1 capitalize text-white text-center tracking-[2px] drop-shadow-[0_0_5px_#00000052] mb-0">{title}</h2> :
                    <h1 className="capitalize text-white text-center tracking-[2px] drop-shadow-[0_0_5px_#00000052] mb-0">{title}</h1>}
                <div className='text-center mt-[15px]'>
                    <ul>
                        <li className='inline-block'>
                            <Link className='text-white md:text-[18px] text-[17px]' to='/'>Home</Link>
                        </li>
                        {blogsLink === "blogsLink" ?
                            <>
                                <span className='px-[10px] text-white text-[18px]'>/</span>
                                <Link className='text-white md:text-[18px] text-[17px]' to='/blogs'>Blogs</Link>
                            </> : null
                        }
                        <span className='px-[10px] text-white md:text-[18px] text-[17px]'>/</span>
                        <li className='inline-block text-white md:text-[18px] text-[17px]'>{title}</li>
                    </ul>
                </div>
            </div>
        </section >
    )
}
export default InnerPageBanner;
import React, { useState } from "react";
import stdCodes from "../components/utils/data/stdCodes.json";
import "react-toastify/dist/ReactToastify.css";
import ImageIcons from "../components/imageComponent/ImageIcons";
import Layout from "../components/shared/layout";
import SEO from "../components/shared/seo";
import {useNavigate,useLocation} from "react-router-dom";
import axios from "axios";
import { windowScroll } from "../components/utils/windowScroll";




const GetMyInternet = () => {
  const [,setDisabled] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  
 
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
    address: "",
    stdCode: stdCodes[16].dial_code,
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const handlePhoneInputChange = (e) => {
    const inputValue = e.target.value.trim();
    const numericValue = inputValue.replace(/\D/g, ""); 

    if (numericValue.length <= 10) {
      setFormData((prevState) => ({ ...prevState, phone: numericValue }));
      setErrors((prevState) => ({ ...prevState, phone: false }));
    }

  }

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Email is invalid";
    if (!formData.phone) newErrors.phone = "Phone number is required";
    if (!formData.city) newErrors.city = "City is required";
    if (!formData.address) newErrors.address = "Address is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validRegex = /^(?!.*(\d)\1{9})[6-9]\d{9}$/;
  // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSubmit = async (event) => {
    event.preventDefault();
    // setDisabled(true);
    if (!validateForm()) {
      console.log('Validation Failed');
      return;
    } else if (formData.phone.length < 10) {
      setErrors((prevState) => ({
        ...prevState,
        // phoneNumber: true,
        phone: "Phone number is invalid!",
      }));
      setDisabled(false);
    } else if (!validRegex.test(formData.phone)) {
      setErrors((prevState) => ({
        ...prevState,
        // phone: true,
        phone: "Phone number must be valid !",
      }));
      setDisabled(false);
    }
    else {
      setDisabled(true);
      try {
        const response = await axios.post(`${process.env.REACT_APP_URL}user/addlead`,
          {
            name: formData.name,
            phone: formData.phone,
            city: formData.city,
            email: formData.email,
            address: formData.address,

          }
        );

        if (response.data) {
          setTimeout(() => {
            setErrors((prevState) => ({ ...prevState, success: false }));
            setDisabled(false);
            setFormData({
              name: '',
              phone: '',
              city: '',
              email:'',
              address:''
            });

          }, 3000);
          windowScroll();
          navigate("/thanks");
          localStorage.setItem('currentPathname', pathname);
        }
        setErrors((prevState) => ({ ...prevState, success: true }));
      }
      catch (errors) {
        console.log('Error adding customer:', errors);
        setErrors(prevState => ({ ...prevState, message: errors.response }));
        setDisabled(false);
      }
    }
  };

  return (
    <>
      <SEO title="Get MyInternet" description="Sign up for MyInternet services." />
      <Layout>
        <section
          className="bg-cover bg-no-repeat mb-[1px]"
          style={{ backgroundImage: `url(${ImageIcons.getMyInternet})` }}
        >
          <div className="container">
            <form
              className="grid grid-cols-1 gap-[15px] md:w-[500px] w-full ml-auto shadow-[0px_0px_40px_0px_rgba(0,0,0,0.2)] bg-[#fffffff7] md:p-[35px] p-[25px] rounded-[5px]"
              onSubmit={handleSubmit}
            >
              <h1 className="lg:text-[30px] md:text-[26px] text-[24px] mb-0">Get MyInternet</h1>
              <div>
                <label
                  className="mb-[8px] inline-block"
                  htmlFor="inputName"
                >
                  Name
                </label>
                <input
                  id="inputName"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Enter Name"
                  className="w-full h-[46px] border border-[#f7dfcd] bg-[#f4853308] px-[15px] rounded-[5px] focus:outline-none focus:border-[#f48533] focus:bg-[#fbf2ee]"
                />
                {errors.name && <p className="text-red-500">{errors.name}</p>}
              </div>

              <div>
                <label
                  className="mb-[8px] inline-block"
                  htmlFor="inputEmail"
                >
                  Email
                </label>
                <input
                  placeholder="example@gmail.com"
                  id="inputEmail"
                  name="email"
                  // type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="w-full h-[46px] border border-[#f7dfcd] bg-[#f4853308] px-[15px] rounded-[5px] focus:outline-none focus:border-[#f48533] focus:bg-[#fbf2ee]"
                />
                {errors.email && <p className="text-red-500">{errors.email}</p>}
              </div>

              <div>
                <label
                  className="mb-[8px] inline-block"
                  htmlFor="inputPhone"
                >
                  Phone
                </label>
                <div className="flex">
                  <select
                    name="stdCode"
                    value={formData.stdCode}
                    onChange={handleInputChange}
                    className="h-[46px] px-[15px] min-w-[120px] !border-r-0 rounded-r-none border border-[#f7dfcd] bg-[#f4853308] rounded-[4px] focus:outline-none"
                  >
                    {stdCodes.map((item, i) => (
                      <option
                        key={i}
                        value={item.dial_code}
                        className="h-[20px]"
                      >
                        {item.dial_code} ({item.code})
                      </option>
                    ))}
                  </select>

                  <input
                    id="inputPhone"
                    name="phone"
                    type="number"
                    value={formData.phone}
                    placeholder="Enter Phone Number"
                    onChange={handlePhoneInputChange}
                    maxLength={10}
                    className="w-full rounded-l-none h-[46px] border border-[#f7dfcd] bg-[#f4853308] px-[15px] rounded-[5px] focus:outline-none focus:border-[#f48533] focus:bg-[#fbf2ee]"
                  />
                </div>
                {errors.phone && <p className="text-red-500">{errors.phone}</p>}
              </div>

              <div>
                <label
                  className="mb-[8px] inline-block"
                  htmlFor="inputCity"
                >
                  City
                </label>
                <input
                  id="inputCity"
                  name="city"
                  value={formData.city}
                  placeholder="Enter City"
                  onChange={handleInputChange}
                  className="w-full h-[46px] border border-[#f7dfcd] bg-[#f4853308] px-[15px] rounded-[5px] focus:outline-none focus:border-[#f48533] focus:bg-[#fbf2ee]"
                />
                {errors.city && <p className="text-red-500">{errors.city}</p>}
              </div>

              <div>
                <label
                  className="mb-[8px] inline-block"
                  htmlFor="inputAddress"
                >
                  Address
                </label>
                <input
                  id="inputAddress"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  placeholder="Enter Address"
                  className="w-full h-[46px] border border-[#f7dfcd] bg-[#f4853308] px-[15px] rounded-[5px] focus:outline-none focus:border-[#f48533] focus:bg-[#fbf2ee]"
                />
                {errors.address && <p className="text-red-500">{errors.address}</p>}
              </div>

              <div>
                <button
                  className="px-[45px] mt-[5px] text-[18px] py-[13px] duration-500 font-medium text-[#fff] bg-[#f48533] rounded-[10px] hover:bg-[#000] ease inline-block"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default GetMyInternet;

import React from "react";
import ImageIcons from '../imageComponent/ImageIcons';
import Slider from "react-slick";
import { Link } from "react-router-dom";
import LazyLoad from 'react-lazy-load';
const Hero = () => {
  let settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    fade: true,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 1000,
    adaptiveHeight: true,
  };
  return (
    <section className="banner bg-[#FBFBFD] lg:overflow-hidden 2xl:pt-[25px] md:pt-[15px] pt-[25px] pb-0 max-md:mb-[15px]">
      <div className='container'>
        <Slider {...settings}>
          {sliderData?.map((item, i) => (
            <div key={i} className='relative'>
              <Link to={item?.handle}>
              <div className='flex lg:flex-nowrap flex-wrap sm:gap-[30px] gap-[25px] justify-center items-center'>
                <div className='2xl:w-[55%] lg:w-[58%] w-[100%] lg:text-left text-center lg:pb-[20px]'>
                  <LazyLoad>
                    <img className="max-lg:m-auto 2xl:!mb-[13px] !mb-[5px] 2xl:w-[420px] xl:w-[350px] md:w-[320px] sm:w-[300px] w-[270px]" src={item.entertainmentIcon} alt={item.entertainmentAlt} />
                  </LazyLoad>
                  <h2 className="mb-[8px] font-semibold 2xl:text-[48px] xl:text-[38px] lg:text-[35px] md:text-[32px] sm:text-[30px] text-[25px]">{item.title}</h2>
                  <h2 className="mb-[10px] text-[#F68140] 2xl:text-[40px] lg:text-[29px] md:text-[28px] sm:text-[26px] text-[22px]">{item.subTitle}</h2>
                  <p className='2xl:mb-[30px] mb-[20px]'>{item.text}</p>
                  <div>
                    <ul className="md:rounded-[16px] rounded-[10px] overflow-hidden inline-flex lg:gap-[3px] sm:gap-[2px] gap-[1px]">
                      {item.subscription?.map((item) => (
                        <li className="bg-[#F68140] inline-block 2xl:py-[14px] py-[10px] xl:px-[25px] sm:px-[20px] px-[10px] text-white 2xl:text-[17px] xl:text-[16px] sm:text-[15px] text-[14px] font-medium 2xl:w-[190px] xl:w-[170px] sm:w-[135px] w-full max-sm:max-w-[112px] 2xl:pr-[55px] xl:pr-[52px] min-w-[85px] leading-[22px]">
                          <LazyLoad className="flex justify-center items-center mb-[5px] p-[5px] rounded-full overflow-hidden bg-[#fff] w-[46.5px] h-[46.5px] max-lg:mx-auto">
                            <img src={item.icon} alt={item.iconAlt} height='' width='' />
                          </LazyLoad>{item.title}</li>
                      ))}
                    </ul>
                  </div>
                </div>
                
                <LazyLoad className='relative 2xl:w-[45%] lg:w-[48%] w-[100%] 2xl:h-[560px] lg:h-[460px] sm:h-[400px] h-[280px]'>
                <img 
                  className="relative z-10 m-auto 2xl:h-[560px] lg:h-[460px] sm:h-[400px] h-[280px]" 
                  src={item.heroImg} 
                  alt={item.heroAlt} 
                  height=''
                  width=''
                />
                </LazyLoad>
              </div>
              </Link>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  )
}
const sliderData = [
  {
    entertainmentIcon: ImageIcons.intertainment,
    entertainmentAlt: "Intertainment",
    title: "Enhance Your internet With our bundled services",
    subTitle: "Wi-Fi + OTT +  IPTV Get 50 Mbps for just ₹399 *",
    text: "upgrade your home internet with our ultimate home internet solutions, where you will get 50 Mbps of speed at just ₹ 399. Enjoy Wi-Fi connectivity, access to OTT platforms and diverse IPTV channels- all in one package.",
    subscription: [
      {
        icon: ImageIcons.freeIptvSubscriptionHeroIcon,
        title: "Free IPTV Subscription",
        iconAlt: "Free IPTV Subscription"
      },
      {
        icon: ImageIcons.unlimitedDataHeroIcon,
        title: "Unlimited Data",
        iconAlt: "Unlimited Data"
      },
      {
        icon: ImageIcons.freeInstallationHeroIcon,
        title: "Free Installation",
        iconAlt: "Free Installation"
      },
    ],
    heroImg: ImageIcons.enhanceInternetBanner,
    heroAlt: "Enhance Your internet",
    handle: '/pricing/home-internet',
  },
  {
    entertainmentIcon: ImageIcons.entertainment,
    entertainmentAlt: "Enter Tainment",
    title: "Business internet with cloud, hosting, and domain solutions",
    subTitle: "Get 40 Mbps speed for just ₹ 499*",
    text: "Enhance your business operations with our business internet solutions providing 40 Mbps speed at just ₹ 499*. Get unlimited data, perfect for your business operations.",
    subscription: [
      {
        icon: ImageIcons.domainHeroIcon,
        title: "Domain",
        iconAlt: "Domain"
      },
      {
        icon: ImageIcons.hostingHeroIcon,
        title: "Hosting",
        iconAlt: "Hosting"
      },
      {
        icon: ImageIcons.cloudCamHeroIcon,
        title: "Cloud Surveillance",
        iconAlt: "Cloud Surveillance"
      },
    ],
    heroImg: ImageIcons.businessInternetBanner,
    heroAlt: "Business internet",
    handle: '/pricing/business-internet',
  },
  {
    entertainmentIcon: ImageIcons.intertainment,
    entertainmentAlt: "Inter Tainment",
    title: "Our Exclusive offers",
    subTitle: "Pay for 6 months toget one month FREE and pay for 12 months to get 3 months FREE!!",
    text: "Enjoy our exclusive offers where you just have to pay for 6 months and will get to enjoy connectivity for 7 months or pay for 12 months and get 2 months internet free. Stay connected with our unbeatable offers",
    subscription: [
      {
        icon: ImageIcons.wifiHeroIcon,
        title: "WIFI",
        iconAlt: "WIFI"
      },
      {
        icon: ImageIcons.ottHeroIcon,
        title: "OTT",
        iconAlt: "OTT"
      },
      {
        icon: ImageIcons.iptvHeroIcon,
        title: "IPTV",
        iconAlt: "IPTV"
      },
    ],
    heroImg: ImageIcons.exclusiveOffersBanner,
    heroAlt: "Our Exclusive offers",
    handle: '/pricing/home-internet',
  },
  {
    entertainmentIcon: ImageIcons.intertainment,
    entertainmentAlt: "Intertainment",
    title: "All Your Internet Needs in One App",
    text: "Simplify your internet experience with our user-friendly app, where you can manage your account,  track billing and payments and enjoy 24/7 customer support- everything at one place.",
    subscription: [
      {
        icon: ImageIcons.manageAccountHeroIcon,
        title: "Manage your account",
        iconAlt: "Manage your account"
      },
      {
        icon: ImageIcons.billingPaymentHeroIcon,
        title: "Billing and payment",
        iconAlt: "Billing and payment"
      },
      {
        icon: ImageIcons.customerSupportHeroIcon,
        title: "Customer Support",
        iconAlt: "Customer Support"
      },
    ],
    heroImg: ImageIcons.myinternetAppBanner,
    heroAlt: "Internet Needs in One App",
    handle: 'https://play.google.com/store/apps/details?id=app.customerportal.digitax&pcampaignid=web_share',
  },
]
export default Hero
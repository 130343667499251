import React, { useState, useRef, useEffect } from 'react';
import Layout from '../components/shared/layout'
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';
import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { IoCheckmarkDoneSharp } from 'react-icons/io5';
import SEO from '../components/shared/seo';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <SEO title='FAQs -myInternet | Your Questions Answered' description='Find answers to all your questions about myInternet. Get detailed information on our services, pricing, support, and more in our comprehensive FAQ section.' />
      <Layout>
        <InnerPageBanner title='Frequently Asked Questions' innerPageBanner={ImageIcons.FAQs} />
        <section>
          <div className="container !max-w-[1100px]">
            <div className="mx-auto bg-white   rounded-lg">

              {faqs.map((faq, index) => (
                <AccordionItem
                  key={index}
                  active={activeIndex === index}
                  handleToggle={() => handleToggle(index)}
                  faq={faq}
                />
              ))}
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

const AccordionItem = ({ handleToggle, active, faq }) => {
  const contentRef = useRef(null);
  const [height, setHeight] = useState('0px');

  useEffect(() => {
    if (contentRef.current) {
      setHeight(active ? `${contentRef.current.scrollHeight}px` : '0px');
    }
  }, [active]);

  return (
    <div className="mb-[20px] shadow-[0px_3px_15px_0px_rgba(0,0,0,0.1)] overflow-hidden rounded-[10px]">
      <div
        className={`p-[20px] flex justify-between items-center cursor-pointer ${active ? 'bg-[#f48533] text-white' : ''}`}
        onClick={handleToggle}
      >
        <h5 className="mb-0 lg:text-[20px] text-[18px] ">{faq.question}</h5>
        <span>{active ? <FaMinus /> : <FaPlus />}</span>
      </div>
      <div
        ref={contentRef}
        className={`overflow-hidden transition-all duration-300`}
        style={{ maxHeight: height }}
      >
        <div className="p-[20px] ">
          <p className='mb-0'>{faq.answer}</p>

          {faq.answerList && <ul className='mt-[15px]'>
            {faq.answerList.map((faq, i) => (
              <li className='mb-[8px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' /> {faq}</li>
            ))}
          </ul>
          }
        </div>
      </div>
    </div>
  );
};

const faqs = [
  {
    question: "In what areas, the services of myInternet are available ?",
    answer: "myInternet was launched in 2016 by providing internet services all over the Pan India. Now our services are available in more than 18 states and we have a community of 100000+ customers."
  },
  {
    question: "Do you offer any additional benefits?",
    answer: "Yes users are offered with many discounts and benefits. One of the greatest advantages offered is that we provide our IPTV services free of cost with internet services subscription. Advance Rental - Pay for 5 month & get 1 Month free , 12 month & get 2 Month free.",
      },
  {
    question: "How to make the payments for my ACT account ?",
    answer: "Yes, through our website, you can pay your bill online."
  },
 {
    question: "How can I check my payment that I will pay ?",
    answer: "If you want to check payment, contact our customer care support team."
  },
  {
    question: " How to check installation charges and security ?",
    answer: "If you make advance payment for new connection for 3, 6 months then your installation and security remains free of cost."
  },
  {
    question: "Why is broadband important?",
    answer: "Broadband, or reliable high-speed internet, allows people more affordable and efficient access to basic amenities such as education,health care, public safety and vernment services by: Affording people opportunities to participate in online learning and distance education."
  },
  {
    question: "How to check myInternet broadband usage?",
    answer: "You can check myInternet broadband usage through our website. Once you log in to your account using the registered mobile number or username password, your broadband usage as well as your session details will be displayed there."
  },
  {
    question: "What is myInternet Usage Policy (FUP) data limit?",
    answer: "There is no FUP limit on myInternet. Every plan provides unlimited data."
  },
  // {
  //   question: "Do I need to sign a contract?",
  //   answer: "No there is no contract and users are provided with full liberty to cancel their subscription whenever they want."
  // },
  // {
  //   question: "Is it possible to change the plan?",
  //   answer: "Yes absolutely, if you want to upgrade your plan, you can do it anytime."
  // },
  // {
  //   question: "Is it possible to schedule installation online?",
  //   answer: "Yes through our website, you can book an online appointment by contacting our customer support service."
  // },
  // {
  //   question: "What equipment is required for getting internet connection installed?",
  //   answer: "The modem and router are provided as a part of our plan. You are also allowed to use your own compatible equipment."
  // },
  // {
  //   question: "How can I contact the technical support team?",
  //   answer: "Our customer support team is available round the clock, so you can contact them via phone, email or live chat options provided on our website"
  // },
  // {
  //   question: "What should I do in case I face issues inmyInternet connectivity?",
  //   answer: "At first, you should restart your modem or router, however, if the problem persists, contact our customer support team."
  // },

];

export default FAQ
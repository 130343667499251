import React from 'react'
import ImageIcons from '../imageComponent/ImageIcons';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazy-load';
const SuitablePlan = () => {
  return (
    <section className="bg-[linear-gradient(0deg,#F2F2F2_0%,#FBFBFD_100%)]">
      <div className='container'>
        <div className='grid grid-cols-2 lg:gap-[40px] sm:gap-[30px] gap-[10px] items-center'>
          <div className='md:shadow-[0px_2px_50px_rgba(255,132,49,0.51)] shadow-[0px_1px_30px_rgba(255,132,49,0.50)] mx-auto w-full lg:p-[42px] p-[20px] max-lg:py-[35px] max-sm:py-[25px] rounded-[5px] text-center max-w-[500px]'>
            <LazyLoad>
              <img src={ImageIcons.area} alt='Availability In Your Area' className='mx-auto sm:mb-[25px] mb-[20px] lg:h-[76px] md:h-[70px] sm:h-[60px] h-[50px] object-contain' height={76} width={76} />
            </LazyLoad>
            <h5 className='sm:mb-[25px] mb-[20px] max-sm:text-[16px]'>Check Availability In Your Area</h5>
            <Link to='/states' className="sm:min-w-[195px] min-w-full sm:px-[50px] px-[5px] sm:text-[18px] text-[14px] sm:py-[12px] py-[10px] text-[#fff] bg-[#F68140] rounded-[10px] hover:bg-[#f7752c] ease inline-block">Check Coverage</Link>
          </div>
          <div className='md:shadow-[0px_2px_50px_rgba(255,132,49,0.51)] shadow-[0px_1px_30px_rgba(255,132,49,0.50)] mx-auto w-full lg:p-[42px] p-[20px] max-lg:py-[35px] max-sm:py-[25px] rounded-[5px] text-center max-w-[500px]'>
            <LazyLoad>
              <img  src={ImageIcons.suitablePlan} alt='Availability In Your Area' className='mx-auto sm:mb-[25px] mb-[20px] lg:h-[76px] md:h-[70px] sm:h-[60px] h-[50px] object-contain' height={76} width={76} />
            </LazyLoad>
            <h5 className='sm:mb-[25px] mb-[20px] max-sm:text-[16px]'>Select A Suitable Plan</h5>
            <Link to='/pricing' className="sm:min-w-[195px] min-w-full sm:px-[50px] px-[5px] sm:text-[18px] text-[14px] sm:py-[12px] py-[10px] text-[#fff] bg-[#F68140] rounded-[10px] hover:bg-[#f7752c] ease inline-block">Price Plan</Link>
          </div>
        </div>
      </div>
    </section>
  )
}
export default SuitablePlan